<template>
    <div>
        <baidu-map ak='GnVkZbGd47aleyQGoGScrNDTixMiT1Ui' center="上海" :zoom="6" :scroll-wheel-zoom="true" class="bm-view" :style="{height:mapHeight}">
          <bml-marker-clusterer :averageCenter="true">
            <bm-marker v-for="item in DataList" :key="item.media_id" :position="{lng: item.media_lng, lat: item.media_lat}" @click="MarkerClick($event,item)">
                 <bm-info-window :show="item.showWindow" @close="infoWindowClose(item)" >
                     <div style="font-size: 12px;">
                         <p>媒体编号：{{item.media_no}}</p>
                         <p>媒体名称：{{item.media_name}}</p>
                         <p>媒体类型：{{item.media_show_type===0?'灯箱广告':(item.media_show_type===1?'道闸广告':(item.media_show_type===2?'电梯框架':(item.media_show_type===3?'广告看板':(item.media_show_type===4?'屏蔽门':(
                             item.media_show_type===5?'候车亭广告':(item.media_show_type===6?'车身广告':'其他'))))))}}</p>
                         <p>媒体地址：{{item.media_community_addr}}</p>
                         <p>社区入住：{{item.media_ruzhu_rate+'%'}}</p>
                         <p>点位尺寸：{{item.media_size}}</p>
                         <div>
                           <label>点位状态：</label>
                           <label :style="{color:(item.media_status===0?'green':'red')}">{{item.media_status===0?'空闲中':'已启用'}}</label>
                         </div>
                        
                     </div>
                 </bm-info-window>
            </bm-marker>
          </bml-marker-clusterer>
        </baidu-map>
    </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow.vue'
import {BmlMarkerClusterer} from 'vue-baidu-map'
import util from '../../util/util'

export default {
  data: function () {
    return {
      mapHeight: 0,
      DataList: [],

      // styles:[{
      //   size:{
      //     width:50,
      //     height:50
      //   },
      //   textColor:'#008CFF',
      //   opt_textSize:10
      // }]
    }
  },
  components: {
    BaiduMap,
    BmMarker,
    BmInfoWindow,
    BmlMarkerClusterer
  },
  created () {
    var screenHeight = document.body.clientHeight
    this.mapHeight = (screenHeight - 100) + 'px'
  },
  mounted () {
    util.Get('media/getmediapointdatalist?source=0').then(res => {
      if (res.rpStatus === 10000) {
        this.DataList = res.list
      }
    })
  },
  methods: {
    MarkerClick (type, item) {
      item.showWindow = true
    },
    infoWindowClose (item) {
      item.showWindow = false
    }
  }
}
</script>

<style scoped>
</style>
